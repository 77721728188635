import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner() {
  return (
    <div
      style={{ minHeight: '200px' }}
      className='d-flex justify-content-center align-items-center'>
      <Spinner variant='light' animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  );
}

export default LoadingSpinner;
