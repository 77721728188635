// import { useState, Suspense } from 'react';
// import { Nav, NavItem } from 'react-bootstrap';
// import { NavLink, Outlet } from 'react-router-dom';
// import Logo from '../../img/logo_std_football_header.png';
// import LoadingPage from '../loadingPage';
// import SidebarLinks from './sidebarLinks';

// const Sidebar = () => {
//   // const [activeIndex, setActiveIndex] = useState(defaultActive || 0);
//   const sidebarItems = [
//     {
//       name: 'Dashboard',
//       route: '/',
//     },
//     {
//       name: 'Output Data',
//       route: 'outputs',
//     },

//     {
//       name: 'Dil Controller',
//       route: 'dilcontroller',
//     },
//   ];

//   return (
//     <div className='d-flex min-vh-100 h-100'>
//       <div className='col-md-2 sidebar min-vh-100 h-100'>
//         <div className='logo-container'>
//           <img className='logo' src={Logo} alt='' />
//         </div>
//         <Nav className='d-flex flex-column align-items-center'>
//           <SidebarLinks title={'Hello'} links={sidebarItems} />
//         </Nav>
//       </div>

//       {/* <Outlet /> */}

//       <Suspense fallback={<LoadingPage />}>
//         <Outlet />
//       </Suspense>
//     </div>
//   );
// };

// export default Sidebar;

import { useState, useEffect, Suspense, useRef } from 'react';
import { OverlayTrigger, Tooltip, Button, Container } from 'react-bootstrap';
import { NavLink, Outlet, redirect } from 'react-router-dom';
import Logo from '../../img/logo_std_football_header.png';
import SmallLogo from '../../img/logo_std_football_small.jpg';
import LoadingPage from '../loadingPage';
import CustomTooltip from '../customTooltip';
import SidebarLinks from './sidebarLinks';
import { logout } from '../../lib/api/index';
import {
  MdOutlineDashboard,
  MdOutlineDataExploration,
  MdOutlineSchema,
  MdInput,
  MdOutlineViewSidebar,
  MdClose,
  MdInsertDriveFile,
  MdOutlineLogout,
  MdSportsSoccer,
  MdOutlineDataSaverOff,
  MdDataExploration,
  MdSpeakerNotes
} from 'react-icons/md';
// function getWindowSize() {
//   const { innerWidth, innerHeight } = window;
//   return { innerWidth, innerHeight };
// }

const Sidebar = () => {
  // const [windowSize, setWindowSize] = useState(getWindowSize());
  const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWindowSize(getWindowSize());
  //   }

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  const sidebarItems = [
    {
      name: 'Dashboard',
      route: '/',
      icon: <MdOutlineDashboard />,
    },
    {
      name: 'Output Data',
      route: 'outputdata',
      icon: <MdOutlineDataExploration />,
    },
    {
      name: 'SF Data',
      route: 'inputdata',
      icon: <MdInsertDriveFile />,
    },
    {
      name: 'DilController',
      route: 'dilcontroller',
      icon: <MdOutlineSchema />,
    },
    {
      name: 'Dominance',
      route: 'dominance',
      icon: <MdInput />,
    },
    {
      name: 'Ingestion',
      route: 'capology',
      icon: <MdOutlineDataSaverOff />,
    },
    {
      name: 'V Teams',
      route: 'vteams',
      icon: <MdSportsSoccer />,
    },
    {
      name: 'Superstats',
      route: 'superstats',
      icon: <MdDataExploration />,
    }
  ];

  const handleLogout = () => {
    localStorage.removeItem('token');
    document.location.reload();
  }

  return (
    <>
      <aside className={`sidebar min-vh-100 h-100 ${!open && 'closed'}`}>
        <header className='sidebar-header'>
          <div style={{ minHeight: '134px' }} className='logo-container'>
            {!open ? (
              <img className='logo' src={SmallLogo} alt='' />
            ) : (
              <img className='logo' src={Logo} alt='' />
            )}
          </div>
          {/* <div className='logo-container'>
              {windowSize.innerWidth < 992 ? (
                <img className='logo' src={SmallLogo} alt='' />
              ) : (
                <img className='logo' src={Logo} alt='' />
              )}
            </div> */}
          {/* <i className='logo-icon uil uil-instagram'></i> */}
        </header>
        <nav>
          {sidebarItems?.map((el) => {
            return (
              <CustomTooltip key={el.name} placement={'right'} content={el.name}>
                <NavLink 
                to={el.route}
                onClick={() => {
                  setOpen(false);
                }}
                >
                  <button>
                    <span>
                      <i>{el.icon}</i>
                      <span>{el.name}</span>
                    </span>
                  </button>
                </NavLink>
              </CustomTooltip>
            );
          })}

          {/* <NavLink to={'/'}>
              <button>
                <span>
                  <i>
                    <MdOutlineDashboard />
                  </i>
                  <span>Dashboard</span>
                </span>
              </button>
            </NavLink>
            <NavLink to={'/outputdata'}>
              <button>
                <span>
                  <i>
                    <MdOutlineDataExploration />
                  </i>
                  <span>Output Data</span>
                </span>
              </button>
            </NavLink>
            <NavLink to={'/dilcontroller'}>
              <button>
                <span>
                  <i>
                    <MdOutlineSchema />
                  </i>
                  <span>DilController</span>
                </span>
              </button>
            </NavLink> */}
          <CustomTooltip placement={'right'} content="Logout">
              <button onClick={handleLogout}>
                <span>
                  <i><MdOutlineLogout /></i>
                  <span>Logout</span>
                </span>
              </button>
          </CustomTooltip>
          <button
            onClick={() => {
              setOpen(!open);
            }}>
            <span>
              <i>{open ? <MdClose /> : <MdOutlineViewSidebar />}</i>
              <span>Close</span>
            </span>
          </button>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
