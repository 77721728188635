import { Entity } from "@amcharts/amcharts5";

const baseUrl = process.env.REACT_APP_BASE_URL;

const credentials = {
  token: localStorage.getItem('token') || '',
};

export const checkAuth = () => {
  return credentials.token;
};

export const getAuth = () => {
  //return btoa(`${credentials.username}:${credentials.password}`);
  //console.log('getAuth', credentials.token )
  return credentials.token;
};

export const getDefaultHeaders = () => {
  const auth = getAuth();
  
  return {
    Authorization: `Bearer ${auth}`
  };
};

export const login = (email, password) => {
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email:email, password: password}),
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const logout = () => {
  /*return fetch(`http://localhost:3100/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({username:username, password: password}),
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });*/
};

export const getPlayers = () => {
  return fetch(`https://node-red.stdfootball.ovh/players`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const getFairValueByPlayer = (id) => {
  return fetch(`https://node-red.stdfootball.ovh/fairvalue/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const testGet = () => {
  return fetch(`https://node-red.stdfootball.ovh/players?fullName=giuseppe`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const getBuildedQuery = (path, query) => {
  // return fetch(`${baseUrl}/api/v0/${path}?page=${page}`, {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/${path}?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const getSuperstatsDil = (entityType, entityId) => {
  return fetch(`https://node-red.stdfootball.ovh/teststatistiche?${entityType}=${entityId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const getBuildedQueryFilters = (path, query) => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/${path}?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

// export const getBuildedDominanceQuery = (path, query) => {
//   return fetch(`${baseUrl}/api/v0/`)
// }
// export const exportExcel = () => {
//   return fetch(`https://node-red.stdfootball.ovh/players`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/vnd.ms-excel',
//     },
//   })
//     .then((r) => r.blob())
//     .then((blob) => {
//       const file = new File([blob], 'prova');
//       console.log(file);
//       var fileUrl = window.URL.createObjectURL(file);
//       var a = document.createElement('a');
//       a.href = fileUrl;
//       a.download = 'projects.xls';
//       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
//       a.click();
//       a.remove(); //afterwards we remove the element again
//     });
// };

export const getElaborationStats = () => {
  return fetch(`https://node-red.stdfootball.ovh/elaborationNums`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};
export const getDominanceStats = () => {
  return fetch(`https://node-red.stdfootball.ovh/dominanceNums`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const postGenerateVirtualTeams = () => {
  return fetch(`https://node-red.stdfootball.ovh/virtualteams`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const getFileExport = (query) => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/${query}&format=xlsx`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/vnd.ms-excel',
    },
  })
    .then((r) => r.blob())
    .then((blob) => {
      const file = new File([blob], query);
      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `${query}.xlsx`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
};

export const postUploadExcelFile = (file, type) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);

  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/players/import`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  }).then((r) => {
    
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};


export const getEntities = () => {
    const token = localStorage.getItem('token');
    return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/entities`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    },
  }).then((r) => {
    if(r.status === 401) {
      localStorage.removeItem("token")
      window.location.reload();
    }
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};

export const getEntity = (entityType) => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/entities/detail/${entityType}`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`
  },
}).then((r) => {
  if(r.status === 401) {
    localStorage.removeItem("token")
    window.location.reload();
  }
  if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
  return r.json();
});
};

export const getOrderParameters = () => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/entities/order`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`
  },
}).then((r) => {
  if(r.status === 401) {
    localStorage.removeItem("token")
    window.location.reload();
  }
  if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
  return r.json();
});
};

export const getWhereParameters = () => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/entities/where`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`
  },
}).then((r) => {
  if(r.status === 401) {
    localStorage.removeItem("token")
    window.location.reload();
  }
  if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
  return r.json();
});
};

export const getModules = () => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/modules`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`
  },
}).then((r) => {
  if(r.status === 401) {
    localStorage.removeItem("token")
    window.location.reload();
  }
  if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
  return r.json();
});
};


export const createVirtualTeam = (entityType, entityId, where, orderBy, module) => {
  const token = localStorage.getItem('token');
  return fetch(`https://stdfootball-manager.stage2.stdfootball.ovh/api/v0/vteam/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({entityId: entityId, entityType:entityType, where:where, orderBy:orderBy, module:module}),
  }).then((r) => {
    if (!r.ok) throw new Error(`Code ${r.status} - ${r.statusText}`);
    return r.json();
  });
};