import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import './styles/main.scss';
import Layout from './Layout';
import PrivateRoute from './lib/helpers/PrivateRoute';



const Dashboard = lazy(() => import('./pages/Dashboard'));
const Output = lazy(() => import('./pages/Output'));
const Superstats = lazy(() => import('./pages/Superstats'));
const Input = lazy(() => import('./pages/Input'));
const DilController = lazy(() => import('./pages/DilController'));
const Login = lazy(() => import('./pages/Login'));
const Dominance = lazy(() => import('./pages/Dominance'));
const Capology = lazy(() => import('./pages/Capology'));
const Vteams = lazy(() => import('./pages/VirtualTeams'));
const Changelog = lazy(() => import('./pages/Changelog'));

function InternalRoutes() {

  return (
    <Routes>
      <Route element={<PrivateRoute/>}>
        <Route path='/' element={<Layout />}>
          <Route index element={<Dashboard/>} />
          <Route path='outputdata' element={<Output />} />
          <Route path='inputdata' element={<Input />} />
          <Route path='dilcontroller' element={<DilController />} />
          <Route path='dominance' element={<Dominance />} />
          <Route path='capology' element={<Capology />} />
          <Route path='vteams' element={<Vteams />} />
          <Route path='superstats' element={<Superstats />} />
          <Route path='changelog' element={<Changelog />} />
        </Route>
      </Route>
      <Route path='login' element={<Login />} />
    </Routes>
  );
}

export default InternalRoutes;
