import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LoadingSpinner from './loadingSpinner';
import Logo from '../img/logo_std_football_header.png';

const LoadingPage = () => {
  return (
    <Container fluid className='d-flex justify-content-center align-items-center'>
      <Row className='d-flex flex-column'>
        <Col>
          <img src={Logo} alt='logo' />
        </Col>
        <Col>
          <LoadingSpinner />
        </Col>
        <Col>
          <h3>Your resource is beeing loaded...</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingPage;
