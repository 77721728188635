import React, { useState } from 'react';

import { Toast } from 'react-bootstrap';
import { MdDownloading } from 'react-icons/md';

let addToastHandler;

export const InfoToast = (title, message) => {
  return typeof window === 'undefined'
    ? null
    : addToast('rgba(4, 168, 163, 1)', <MdDownloading />, title, message);
};

const addToast = (color, icon, title, message) => {
  const toastOptions = { color, icon, title, message };

  return typeof window === 'undefined' ? null : addToastHandler(toastOptions);
};

export const ToastComponentList = () => {
  const [toast, setToast] = useState({});
  const [showToast, setShowToast] = useState(false);

  addToastHandler = (toastOptions) => {
    return typeof window === 'undefined' ? null : (setToast(toastOptions), setShowToast(true));
  };

  return (
    <Toast
      className='global-toast'
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={5000}
      autohide>
      <Toast.Header className='d-flex bd-highlight'>
        <div className='p-2 bd-highlight' style={{ fill: toast.color }}>
          {toast.icon}
        </div>
        <strong className='p-2 bd-highlight mr-auto' style={{ color: toast.color }}>
          {toast.title}
        </strong>
      </Toast.Header>
      <Toast.Body className='p-3'>{toast.message}</Toast.Body>
    </Toast>
  );
};
