import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import InternalRoutes from './Routes';
import Sidebar from './components/sidebar/sidebar';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingPage from './components/loadingPage';
function Layout(props) {
  return (
    <Container fluid className='m-0 p-0'>
      <Row className='m-0 p-0'>
        <Col className='col-12'>
          <Sidebar />
        </Col>
        <Col style={{ paddingLeft: '85px' }}>
          <Suspense fallback={<LoadingPage />}>
            <Outlet />
          </Suspense>
        </Col>
      </Row>
    </Container>
  );
}

export default Layout;
