import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomTooltip = ({ content, placement, children }) => {
  const renderTooltip = (props) => (
    <Tooltip id='tooltip' {...props}>
      {content}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
