import { ToastComponentList } from './components/toastComponent';
import InternalRoutes from './Routes';
import React, { useEffect } from 'react';
import './styles/main.scss';



function App() {

  return (
    <div className='App'>
      <InternalRoutes />
      <ToastComponentList />
    </div>
  );
}

export default App;
